<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="query" :inline="true" :model="query" size="medium">
        <el-form-item prop="searchDate">
          <el-date-picker
              v-model="query.searchDate"
              placeholder="请选择年月"
              type="month"
              value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="doReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
      </div>
      <el-table
          v-loading="isLoading"
          :data="dataList"
          border
          class="common-table"
          size="medium"
          style="width: 100%"
      >
        <el-table-column label="创建时间" prop="createDate" width="240px"/>
        <el-table-column label="摘要" prop="summary" width="400px"/>
        <el-table-column label="收入" prop="recharge" width="160px"/>
        <el-table-column label="支出" prop="pay" width="160px"/>
        <el-table-column label="现金类型" prop="crashType"/>
        <el-table-column label="来源ID" prop="sourceId"/>
        <el-table-column label="备注" prop="mark" width="240px"/>
      </el-table>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import {getUserCardFlow} from "@/api/user";

export default {
  name: "user_bills",
  mixins: [BaseMixin],
  data() {
    return {
      query: {
        searchDate: "",
        userId: "",
      },
      dataList: [
        {
          id: "",
          createDate: "创建时间",
          recharge: "充值",
          pay: "",
          crashType: "阳光",
          sourceId: "",
          mark: "",
          summary: "",
        },
      ],
    };
  },
  methods: {
    async search() {
      const params = this.paramFormat(this.query);

      this.isLoading = true;
      const res = await getUserCardFlow(params);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        this.dataList = res.returnObject.list;
        this.pagination.totalSize = res.returnObject.count;
        this.pagination.totalPages = res.returnObject.totalPage;
      }
    },
    doReset() {
      this.$refs.query.resetFields();
      this.doSearch();
    },
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
  },
  mounted() {
    this.query.userId = this.$route.query.id;
    this.doSearch();
  },
};
</script>

<style lang="less" scoped>
</style>
